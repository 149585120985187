function CircleItem7(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="81" height="81"
             viewBox="0 0 81 81">
            <defs>
                <style>{`.a{fill:#233e99;}.b{fill:#fff;}.c{filter:url(#a);}`}</style>
                <filter id="a" x="0" y="0" width="81" height="81" filterUnits="userSpaceOnUse">
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="3" result="b"/>
                    <feFlood floodOpacity="0.161"/>
                    <feComposite operator="in" in2="b"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g transform="translate(9 6)">
                <g className="c" transform="matrix(1, 0, 0, 1, -9, -6)">
                    <circle className="a" cx="31.5" cy="31.5" r="31.5" transform="translate(9 6)"/>
                </g>
                <path className="b"
                      d="M22.439,4.533A5.38,5.38,0,0,0,17.393,1H8.374A5.38,5.38,0,0,0,3.328,4.533l-2.9,7.974A7.155,7.155,0,0,0,0,14.957v1.074A5.346,5.346,0,0,0,2.147,20.3c0,.009,0,.015,0,.024v2.147a3.221,3.221,0,0,0,6.442,0V21.4h8.589v1.074a3.221,3.221,0,0,0,6.442,0V20.3a5.346,5.346,0,0,0,2.147-4.271V14.957a7.144,7.144,0,0,0-.429-2.449Zm-17.09.734a3.231,3.231,0,0,1,3.026-2.12h9.019a3.231,3.231,0,0,1,3.028,2.12l2.349,6.469H3ZM6.442,22.473a1.074,1.074,0,0,1-2.147,0V21.292a5.368,5.368,0,0,0,1.074.107H6.442Zm15.031,0a1.074,1.074,0,0,1-2.147,0V21.4H20.4a5.368,5.368,0,0,0,1.074-.107Zm2.147-6.442A3.221,3.221,0,0,1,20.4,19.252H5.368a3.221,3.221,0,0,1-3.221-3.221V14.957a5,5,0,0,1,.13-1.074H4.295v1.074a1.074,1.074,0,0,0,2.147,0V13.884H19.326v1.074a1.074,1.074,0,0,0,2.147,0V13.884H23.49a5,5,0,0,1,.13,1.074Z"
                      transform="translate(18.98 17.223)"/>
            </g>
        </svg>
    );
}

export default CircleItem7;