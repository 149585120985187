function CircleItem4(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="82" height="81"
             viewBox="0 0 82 81">
            <defs>
                <style>{`.a{fill:#233e99;}.b{fill:#fff;}.c{filter:url(#a);}`}</style>
                <filter id="a" x="0" y="0" width="82" height="81" filterUnits="userSpaceOnUse">
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="3" result="b"/>
                    <feFlood floodOpacity="0.161"/>
                    <feComposite operator="in" in2="b"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g transform="translate(9 6)">
                <g className="c" transform="matrix(1, 0, 0, 1, -9, -6)">
                    <ellipse className="a" cx="32" cy="31.5" rx="32" ry="31.5" transform="translate(9 6)"/>
                </g>
                <path className="b"
                      d="M12.884,0A12.884,12.884,0,1,0,25.768,12.884,12.884,12.884,0,0,0,12.884,0ZM23.162,9.792,13.6,11.651a5.3,5.3,0,0,1-.4-.729L9.617,2.655A10.723,10.723,0,0,1,23.162,9.792ZM12.884,23.62a10.731,10.731,0,0,1-5.235-20.1l3.584,8.257a7.527,7.527,0,0,0,2.327,2.976L20.7,20.22A10.7,10.7,0,0,1,12.884,23.62Zm9.126-5.105-6.576-5.033L23.573,11.9c.029.322.049.652.049.983a10.656,10.656,0,0,1-1.613,5.631Z"
                      transform="translate(18.309 19.703)"/>
            </g>
        </svg>
    );
}

export default CircleItem4;