function CircleItem3(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="81" height="82"
             viewBox="0 0 81 82">
            <defs>
                <style>{`.a{fill:#233e99;}.b{fill:#fff;}.c{filter:url(#a);}`}</style>
                <filter id="a" x="0" y="0" width="81" height="82" filterUnits="userSpaceOnUse">
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="3" result="b"/>
                    <feFlood floodOpacity="0.161"/>
                    <feComposite operator="in" in2="b"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g transform="translate(9 6)">
                <g className="c" transform="matrix(1, 0, 0, 1, -9, -6)">
                    <ellipse className="a" cx="31.5" cy="32" rx="31.5" ry="32" transform="translate(9 6)"/>
                </g>
                <path className="b"
                      d="M20.178,3.245V1.074a1.074,1.074,0,0,0-2.147,0V2.255a5.368,5.368,0,0,0-1.074-.107H15.884V1.074a1.074,1.074,0,0,0-2.147,0V2.147H11.589V1.074a1.074,1.074,0,0,0-2.147,0V2.147H8.368a5.368,5.368,0,0,0-1.074.107V1.074a1.074,1.074,0,1,0-2.147,0V3.245A5.346,5.346,0,0,0,3,7.516V20.4a5.375,5.375,0,0,0,5.368,5.368h8.589A5.375,5.375,0,0,0,22.326,20.4V7.516a5.346,5.346,0,0,0-2.147-4.271Zm0,17.155a3.221,3.221,0,0,1-3.221,3.221H8.368A3.221,3.221,0,0,1,5.147,20.4V7.516A3.221,3.221,0,0,1,8.368,4.295h8.589a3.221,3.221,0,0,1,3.221,3.221ZM18.031,8.589a1.074,1.074,0,0,1-1.074,1.074H8.368a1.074,1.074,0,0,1,0-2.147h8.589A1.074,1.074,0,0,1,18.031,8.589Zm0,4.295a1.074,1.074,0,0,1-1.074,1.074H8.368a1.074,1.074,0,0,1,0-2.147h8.589A1.074,1.074,0,0,1,18.031,12.884Zm-4.295,4.295a1.074,1.074,0,0,1-1.074,1.074H8.368a1.074,1.074,0,0,1,0-2.147h4.295A1.074,1.074,0,0,1,13.736,17.178Z"
                      transform="translate(18.394 19.528)"/>
            </g>
        </svg>
    );
}

export default CircleItem3;