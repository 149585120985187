import CircleItem1 from "../public/images/jsx/CircleItem1";
import CircleItem2 from "../public/images/jsx/CircleItem2";
import CircleItem3 from "../public/images/jsx/CircleItem3";
import CircleItem4 from "../public/images/jsx/CircleItem4";
import CircleItem5 from "../public/images/jsx/CircleItem5";
import CircleItem6 from "../public/images/jsx/CircleItem6";
import CircleItem7 from "../public/images/jsx/CircleItem7";


const circles = [<CircleItem1 key={1}/>, <CircleItem2 key={2}/>, <CircleItem3 key={3}/>, <CircleItem4 key={4}/>,
    <CircleItem5 key={5}/>, <CircleItem6 key={6}/>, <CircleItem7 key={7}/>]

function Circle({num, text, pos}) {
    return (
        <div className={`circle ${num}`}>
            {circles[pos]}
            <p>{text}</p>
        </div>
    );
}

export default Circle;