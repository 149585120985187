function CircleItem5(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="82" height="82"
             viewBox="0 0 82 82">
            <defs>
                <style>{`.a{fill:#233e99;}.b{fill:#fff;}.c{filter:url(#a);}`}</style>
                <filter id="a" x="0" y="0" width="82" height="82" filterUnits="userSpaceOnUse">
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="3" result="b"/>
                    <feFlood floodOpacity="0.161"/>
                    <feComposite operator="in" in2="b"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g transform="translate(9 6)">
                <g className="c" transform="matrix(1, 0, 0, 1, -9, -6)">
                    <circle className="a" cx="32" cy="32" r="32" transform="translate(9 6)"/>
                </g>
                <path className="b"
                      d="M20.4,1H5.368A5.375,5.375,0,0,0,0,6.368v8.589a5.375,5.375,0,0,0,5.368,5.368H11.81v2.147H7.516a1.074,1.074,0,1,0,0,2.147H18.252a1.074,1.074,0,0,0,0-2.147H13.957V20.326H20.4a5.375,5.375,0,0,0,5.368-5.368V6.368A5.375,5.375,0,0,0,20.4,1ZM5.368,3.147H20.4A3.221,3.221,0,0,1,23.62,6.368v7.516H2.147V6.368A3.221,3.221,0,0,1,5.368,3.147ZM20.4,18.178H5.368a3.221,3.221,0,0,1-3.023-2.147H23.423A3.221,3.221,0,0,1,20.4,18.178Z"
                      transform="translate(18.716 18.878)"/>
            </g>
        </svg>
    );
}

export default CircleItem5;