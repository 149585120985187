import {useState} from "react";
import ShowMoreModal from "./ShowMoreModal";
import Image from "next/image";
import Skeleton from "react-loading-skeleton";
import myLoader from "../utils/myLoader";
import {useRouter} from "next/router";
import UseTranslations from "../utils/useTranslations";

function HomePageCard({
                          name,
                          img,
                          shape,
                          description,
                          loader = false,
                          pdfEng,
                          pdfGeo,
                          pdfRus,
                          redirectAddress,
                          showMoreRedirectAddress,
                          bgColor,
                          className
                      }) {

    const [modalShow, setModalShow] = useState(false);
    const router = useRouter();
    const {t} = UseTranslations();
    return (
        <div className={"HomePageCard"}>
            {
                <>
                    <div className={`img-container-${shape}`}>
                        <Image loader={loader && myLoader} src={img} alt={"project"} layout={"fill"} objectFit={"cover"}
                               priority/>
                    </div>

                    {/*<img className={`img shape-${shape}`} src={img} alt='project'/>*/}
                    <h1>{name}</h1>
                    <div className={"show-more"} onClick={() => {
                        if (showMoreRedirectAddress) {
                            router.push(showMoreRedirectAddress)
                        } else {
                            setModalShow(true)
                        }
                    }}>
                        {t("Show more")}
                    </div>
                    <ShowMoreModal
                        className={className}
                        img={img}
                        pdfGeo={pdfGeo}
                        pdfEng={pdfEng}
                        pdfRus={pdfRus}
                        loader={loader}
                        myLoader={loader && myLoader}
                        info={description}
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        redirectAddress={redirectAddress}
                        bgColor={bgColor}
                    />
                </>
                ||
                <Skeleton/>
            }

        </div>
    );
}

export default HomePageCard;
