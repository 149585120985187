import {Form, Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import FileInputWithPreview from "./Inputs/FileInputWithPreview";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import api from "../utils/api";
import UseTranslations from "../utils/useTranslations";

export default function HireUsModal(props) {

    const {handleSubmit, register, setValue, getValues, watch, reset} = useForm({})
    const [errors, setErrors] = useState({})
    const { t } = UseTranslations();

    useEffect(() => {
        reset({firstName: '', lastName: '', email: '', phone: ''});
        setErrors({});
    }, [props.show]);  // eslint-disable-line react-hooks/exhaustive-deps

    const HireUs = (data) => {
        // console.log("data", data)

        api.post("hire-us", data).then(res => {
            if (res.data.errors) {
                setErrors(res.data.errors)
            } else {
                props.onHide()
            }
        })
    }

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={"join-us-modal hire"}
        >
            <img className={"puzzle hire"} src={"/images/png/hire_us.png"} alt={"background"}/>
            <Modal.Body>
                <h4>{t('hire_us_title')}</h4>
                <Form className={"form"} onSubmit={handleSubmit(HireUs)}>

                    <div className={"left"}>
                        <Form.Group className={`group ${errors?.firstName && "error"}`} controlId="formFirstName"
                                    onChange={() => setErrors({...errors, firstName: ''})}>
                            <Form.Control className={"control"} type="text" {...register("firstName")}
                                          placeholder={t('first_name')}/>
                        </Form.Group>

                        <Form.Group className={`group ${errors?.lastName && "error"}`} controlId="formLastName"
                                    onChange={() => setErrors({...errors, lastName: ''})}>
                            <Form.Control className={"control"} type="text" {...register("lastName")}
                                          placeholder={t('last_name')}/>
                        </Form.Group>

                        <Form.Group className={`group ${errors?.email && "error"}`} controlId="formEmail"
                                    onChange={() => setErrors({...errors, email: ''})}>
                            <Form.Control className={"control"} type="text" {...register("email")}
                                          placeholder={t('email')}/>
                        </Form.Group>

                        <Form.Group className={`group ${errors?.phone && "error"}`} controlId="formPhone"
                                    onChange={() => setErrors({...errors, phone: ''})}>
                            <Form.Control className={"control form-number-input"} type="number" {...register("phone")}
                                          placeholder={t('phone')}/>
                        </Form.Group>

                        <div className={"actions"}>
                            <Button className={"close"} onClick={props.onHide}>{t('close')}</Button>
                            <Button className={"submit"} type="submit">{t('submit')}</Button>
                        </div>
                    </div>

                </Form>
            </Modal.Body>

        </Modal>
    );
}

