import PartnersCarousel from "../components/PartnersCarousel";
import HomePageCard from "../components/HomePageCard";
import Circle from "../components/circle";
import YoutubeEmbed from "../utils/YoutubeEmbed";
import UserLayout from "../components/layouts/UserLayout";
import { useEffect, useState } from "react";
import getYoutubeEmbedID from "../utils/getYoutubeEmbedID";
import Head from "next/head";
import api from "../utils/api";
import JoinUsModal from "../components/JoinUsModal";
import HireUsModal from "../components/HireUsModal";
import ShowMoreModal from "../components/ShowMoreModal";
import { useRouter } from "next/router";
import useWindowSize from "../utils/WindowSize";
import Image from "next/image";
import apiBack from "../utils/apiBack";
import myLoader from "../utils/myLoader";
import UseTranslations from "../utils/useTranslations";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import FacebookMsg from "../components/FacebookMsg";
import SwiperCore, { Mousewheel } from "swiper/core";

Home.layout = "UserLayout";

export default function Home({ partnersData, newsData, projectsData }) {
  const router = useRouter();
  const { t, locale } = UseTranslations();
  const [embedId, setEmbedId] = useState();
  const { width } = useWindowSize();
  const [joinUsModalShow, setJoinUsModalShow] = useState(false);
  const [hireUsModalShow, setHireUsModalShow] = useState(false);
  const [about, setAbout] = useState();

  useEffect(() => {
    if (hireUsModalShow || joinUsModalShow) {
      setTimeout(() => {
        document.querySelector(".modal-backdrop").style.width = 0;
      }, 1);
    }
  }, [hireUsModalShow, joinUsModalShow]);

  const [modalShow, setModalShow] = useState(false);
  const fetchData = () => {
    api.get("about-us/000000000000000000000001")
        .then(response => {
          const {eng, ka} = response.data;
          setAbout({eng: eng.description, ka: ka.description});
        })
        .catch(error => {
          console.error("Error fetching about data:", error);
        });
  };

  useEffect(() => {
    fetchData();
    getYoutubeVideoUrl();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getYoutubeVideoUrl = () => {
    api.get(`youtube-link`).then((res) => {
      if (res.data[0]) setEmbedId(getYoutubeEmbedID(res.data[0].link));
    });
  };
  SwiperCore.use([Mousewheel]);

  return (
    <main style={{ flex: 1 }}>
      <Head>
        <title>Softgen</title>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="description" content="Softgen programming group" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta title={"About us"} />
        <meta name="DC.Title" content={"About-us"} />
        <meta property="og:title" content={"About-us"} />
        {/*<meta name="description" content={aboutInfo?.title}/>*/}
        {/*<meta property="og:description" content={aboutInfo?.title}/>*/}
        <meta
          name="image"
          content={`https://softgen.softgen.ge/images/img/logo.png`}
        />
        <meta
          property="og:image"
          content={`https://softgen.softgen.ge/images/img/logo.png`}
        />
        <meta name="keywords" content={"About us | About-us"} />
        {/*<meta property="og:url" content={`${basePath}/about-us`}/>*/}
      </Head>

      <div className={"home-page"}>
        <div className={"binary-earth-container"}>
          <img
            className={"binary-earth"}
            src={"/images/webp/binary_earth.webp"}
            alt="binary-earth"
          />
        </div>
        <div className={"providing-reliable-software-block"} id={"home"}>
          <div className={"left"}>
            <h1 className={"header software"} dangerouslySetInnerHTML={{ __html: t('reliable_software') }} />
            <YoutubeEmbed
              embedId={embedId}
              classname="video-responsive"
              homePage={true}
            />
            <img
              className={"hand"}
              src={"/images/webp/hand.webp"}
              alt="video-hand"
            />
          </div>
          <div className={"right"}>
            <div className={"container"}>
              <div className={"circle-box"}>
                <Circle num="one" pos={0} />
                <Circle num="two" pos={1} />
                <Circle num="three" pos={2} />
                <Circle num="four" pos={3} />
                <Circle num="five" pos={4} />
                <Circle num="six" pos={5} />
                <Circle num="seven" pos={6} />
              </div>
              <img
                className={"circle-guy"}
                src={"/images/webp/circle_guy.webp"}
                alt="circle-guy"
              />
              <div className={"blue-circle"} />
            </div>
          </div>
        </div>
        <div className={"news-block"} id={"news"}>
          <h1 className={"header news"} onClick={() => router.push("/news")}>
          {/*<h1 className={"header news"}>*/}
            {t('News')}
          </h1>
          <div className={"news-block__container"}>
            {width > 1000 &&
                <div className={"left"}>
                  <div className={`news-img-container`}>
                    {newsData.data?.length && (
                        <Image
                            alt={"last news"}
                            loader={myLoader}
                            onClick={() =>
                                router.push(`/news/${newsData?.data[0]?._id}`)
                            }
                            src={newsData?.data[0]?.img}
                            layout="fill"
                            objectFit={"cover"}
                            priority
                        />
                    )}
                    <h2>
                      {newsData.data && newsData?.data[0]?.[locale]?.title}
                    </h2>
                  </div>


                  {/*<div className={"show-more"} onClick={() => setModalShow(true)}>*/}
                  {/*    Show more*/}
                  {/*</div>*/}
                  {newsData.data && (
                      <ShowMoreModal
                          img={"/api/uploads/" + newsData?.data[0]?.img}
                          info={newsData[0]?.[locale]?.description}
                          show={modalShow}
                          loader={myLoader}
                          onHide={() => setModalShow(false)}
                      />
                  )}
                </div>
            }
            <div className={"right"}>
              {newsData.data &&
                newsData?.data.slice(width > 1000 ? 1 : 0, width > 1000 ? 4 : 3)?.map((item, key) => {
                  return (
                    <div className={`news-img-container secondary`} key={key}>
                      <Image
                        alt={"news"}
                        onClick={() => router.push(`/news/${item._id}`)}
                        src={item.img}
                        layout="fill"
                        loader={myLoader}
                        objectFit={"cover"}
                        priority
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <div className={"about-block"} id={"about"}>
          <div className={"rocket"}>
            <Image
              src={"/images/png/launch.png"}
              alt={"about softgen launch"}
              layout={"fill"}
              objectFit={"cover"}
            />
          </div>
          <h1 className={"header about"}>
            {t('About Us')}
          </h1>
          <div className={"about-container"}>
            <p>{locale === 'ka' ? about?.ka : about?.eng}</p>
            {/*<div dangerouslySetInnerHTML={{ __html: t(locale === 'eng' && (width > 1250 || (width < 1000 && width > 650)) ? 'about_us_text_width_depends' : 'about_us_text') }} />*/}
          </div>
        </div>

        <div className={"what-we-do-block"} id={"services"}>
          <h1 className={"header do"}>{t("What We Do?")}</h1>
          {/*<div className={'cards-box'}>*/}
          <Swiper
              mousewheel={{
                enabled: true,
                forceToAxis: true,
                releaseOnEdges: true,
                invert: false,
                sensitivity: 1,
              }}
              className="project_swiper"
              slidesPerView={"auto"}
              spaceBetween={20}
              navigation={true}
              modules={[Navigation]}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                610: {
                  slidesPerView: 2,
                },
                870: {
                  slidesPerView: 3,
                },
              }}
          >
            <SwiperSlide>
            <HomePageCard name={t("Mobile & Desktop Apps")} bgColor={'f'} shape={"square"} className="project_modal"
                          img="/images/other/test_4.jpeg" redirectAddress={'/projects/type/md'} description={t('m_d')}/>
            </SwiperSlide>

            <SwiperSlide>
            <HomePageCard name={t("ERP & CRM")} bgColor={'s'} shape={"square"} className="project_modal"
                          img={"/images/other/test_2.jpeg"} redirectAddress={'/projects/type/ec'} description={t('e_c')}/>
            </SwiperSlide>

            <SwiperSlide>
            <HomePageCard name={t("Enterprise Solutions")} bgColor={'t'} shape={"square"} className="project_modal"
                          img="/images/other/test_3.jpeg" redirectAddress={'/projects/type/cs'} description={t('e_s')}/>
            </SwiperSlide>
          </Swiper>
          {/*</div>*/}
        </div>
        <div className={"home-background-wave-container"}>
          <Image
            src={"/images/webp/home_background_wave.webp"}
            alt={"background"}
            layout={"fill"}
            objectFit={"cover"}
          />
        </div>
        <div className={"latest-projects-block"} id={"projects"}>
          <h1
            className={"header projects"}
            onClick={() => router.push("/projects")}
          >
            {t('Projects')}
          </h1>
          <Swiper
              mousewheel={{
                enabled: true,
                forceToAxis: true,
                releaseOnEdges: true,
                invert: false,
                sensitivity: 1,
              }}
              className="project_swiper"
              slidesPerView={"auto"}
              spaceBetween={20}
              navigation={true}
              modules={[Navigation]}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                610: {
                  slidesPerView: 2,
                },
                870: {
                  slidesPerView: 3,
                },
              }}
          >
            {projectsData.data &&
              projectsData.data?.map((project) => (
                  <SwiperSlide key={project._id}>
                      <HomePageCard
                          name={project[locale]?.name}
                          // showMoreRedirectAddress={"/projects"}
                          description={project[locale]?.description}
                          shape={"square"}
                          img={project.img}
                          loader={true}
                          pdfGeo={project.pdf_file_geo}
                          pdfRus={project.pdf_file_rus}
                          pdfEng={project.pdf_file_eng}
                      />
                    </SwiperSlide>
                ))}
          </Swiper>
        </div>
        <div className={"partners-block"} id={"partners"}>
          <h1 className={"header partners"}>{t('Our Partners')}</h1>
          <PartnersCarousel partners={partnersData} />
          <div className={"partner-boy"}>
            <Image
              src={"/images/png/partner_boy.png"}
              alt={"image"}
              width={456}
              height={523}
              priority
            />
          </div>
        </div>

        <div className={"hello-block"} id={"services"}>
          <h1 className={"header hello"}>{t("Hello")}</h1>
          <p>{t("what can we help with?")}</p>
          <div className={"joinOrHire"}>
            <div className={"join"} onClick={() => setJoinUsModalShow(true)}>
              <p>{t('Join us')}</p>
            </div>
            <JoinUsModal
              show={joinUsModalShow}
              onHide={() => setJoinUsModalShow(false)}
            />
            <p style={{ marginBottom: 0 }}> {t('or')} </p>
            <div className={"hire"} onClick={() => setHireUsModalShow(true)}>
              <p>{t('Hire us')}</p>
            </div>
            <HireUsModal
              show={hireUsModalShow}
              onHide={() => setHireUsModalShow(false)}
            />
          </div>
          <a href="mailto:info@softgen.ge" >info@softgen.ge</a>
          <a href="tel:032 2 054343" >032 2 054343</a>
          <a href="tel:+995 514 514 004" >995 514 514 004</a>

          <div className={"couch_guy"}>
            <Image
              src={"/images/png/couch_guy.png"}
              alt="couch_guy"
              width={523}
              height={431}
              priority
            />
          </div>
        </div>
      </div>
      <FacebookMsg/>
    </main>
  );
}

export async function getServerSideProps() {
  let partnersData, newsData, projectsData;

  try {
    partnersData = (await apiBack.get("partners")).data;
    newsData = (await apiBack.get("news")).data;
    projectsData = (await apiBack.get("projects")).data;
  } catch (err) {
    console.log(err);
  }

  return {
    props: {
      partnersData: partnersData || [],
      newsData: newsData || [],
      projectsData: projectsData || [],
    },
  };
}
