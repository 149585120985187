function CircleItem6(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="80" height="81"
             viewBox="0 0 80 81">
            <defs>
                <style>{`.a{fill:#233e99;}.b{fill:#fff;}.c{filter:url(#a);}`}</style>
                <filter id="a" x="0" y="0" width="80" height="81" filterUnits="userSpaceOnUse">
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="3" result="b"/>
                    <feFlood floodOpacity="0.161"/>
                    <feComposite operator="in" in2="b"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g transform="translate(9 6)">
                <g className="c" transform="matrix(1, 0, 0, 1, -9, -6)">
                    <ellipse className="a" cx="31" cy="31.5" rx="31" ry="31.5" transform="translate(9 6)"/>
                </g>
                <path className="b"
                      d="M16.957,0H8.368A5.375,5.375,0,0,0,3,5.368V20.4a5.375,5.375,0,0,0,5.368,5.368h8.589A5.375,5.375,0,0,0,22.326,20.4V5.368A5.375,5.375,0,0,0,16.957,0Zm3.221,20.4a3.221,3.221,0,0,1-3.221,3.221H13.736V22.547a1.074,1.074,0,0,0-2.147,0V23.62H8.368A3.221,3.221,0,0,1,5.147,20.4V5.368A3.221,3.221,0,0,1,8.368,2.147h8.589a3.221,3.221,0,0,1,3.221,3.221Zm-3.221-3.221a1.074,1.074,0,0,1-1.074,1.074H9.442a1.074,1.074,0,1,1,0-2.147h6.442A1.074,1.074,0,0,1,16.957,17.178Zm-6.442-3.221a1.074,1.074,0,0,0,1.074-1.074V11.81h2.147v1.074a1.074,1.074,0,1,0,2.147,0V8.589a3.221,3.221,0,0,0-6.442,0v4.295A1.074,1.074,0,0,0,10.516,13.957Zm2.147-6.442a1.074,1.074,0,0,1,1.074,1.074V9.663H11.589V8.589A1.074,1.074,0,0,1,12.663,7.516Z"
                      transform="translate(19.406 20.37)"/>
            </g>
        </svg>
    );
}

export default CircleItem6;