import {Form, Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import FileInputWithPreview from "./Inputs/FileInputWithPreview";
import api from "../utils/api";
import UseTranslations from "../utils/useTranslations";

export default function JoinUsModal(props) {

    const {control, handleSubmit, register, setValue, getValues, watch, reset} = useForm({})
    const [errors, setErrors] = useState({})
    const { t } = UseTranslations();

    useEffect(() => {
        reset({firstName: '', lastName: '', email: '', phone: '', file: ''});
        setErrors({});
    }, [props.show]);  // eslint-disable-line react-hooks/exhaustive-deps

    const JoinUs = (data) => {

        let formData = new FormData();
        Object.keys(data).map((key) =>
            formData.append(
                key,  data[key]
            )
        );

        api.post("join-us", formData).then(res => {
            if (res.data.errors) {
                setErrors(res.data.errors)
            } else {
                props.onHide()
            }
        })
    }

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={"join-us-modal"}
        >
            <img className={"puzzle"} src={"/images/png/puzzle.png"} alt={"background"}/>
            <Modal.Body>
                <h4>{t('join_us_title')}</h4>
                <Form className={"form"} onSubmit={handleSubmit(JoinUs)}>
                    <div className={"left"}>
                        <Form.Group className={`group ${errors?.firstName && "error"}`} controlId="formFirstName"
                                    onChange={() => setErrors({...errors, firstName: ''})}>
                            <Form.Control className={"control"} type="text" {...register("firstName")}
                                          placeholder={t('first_name')}/>
                        </Form.Group>

                        <Form.Group className={`group ${errors?.lastName && "error"}`} controlId="formLastName"
                                    onChange={() => setErrors({...errors, lastName: ''})}>
                            <Form.Control className={"control"} type="text" {...register("lastName")}
                                          placeholder={t('last_name')}/>
                        </Form.Group>

                        <Form.Group className={`group ${errors?.email && "error"}`} controlId="formEmail"
                                    onChange={() => setErrors({...errors, email: ''})}>
                            <Form.Control className={"control"} type="text" {...register("email")}
                                          placeholder={t('email')}/>
                        </Form.Group>

                        <Form.Group className={`group ${errors?.phone && "error"}`} controlId="formPhone"
                                    onChange={() => setErrors({...errors, phone: ''})}>
                            <Form.Control className={"control"} type="text" {...register("phone")}
                                          placeholder={t('phone')}/>
                        </Form.Group>

                        <div className={"actions"}>
                            <Button className={"close"} onClick={props.onHide}>{t('close')}</Button>
                            <Button className={"submit"} type="submit">{t('submit')}</Button>
                        </div>
                    </div>

                    <div className={"right"}>
                        <label className={"attach"} htmlFor="kk">{t('attach')}</label>
                        <div>{getValues("file")?.name}</div>
                        <div className={"user-error-field"}>{errors?.file}</div>
                        <Form.Group controlId="formFile" className={"basic-attach"}
                                    onChange={() => setErrors({...errors, file: ''})}>
                            <FileInputWithPreview name={"file"} register={register} setValue={setValue} watch={watch}/>
                        </Form.Group>
                    </div>
                </Form>
            </Modal.Body>

        </Modal>
    );
}

