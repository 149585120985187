import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import {useRef, useState, useTransition} from "react";
import {FreeMode, Navigation, Thumbs} from "swiper";
import Image from "next/image";
import myLoader from "../utils/myLoader";
function PartnersCarousel({partners}) {
    const swiperRef = useRef(null);
    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const {locale}= useTransition()

    const setInfo = (name, description) => {
        setName(name);
        setDescription(description)
    }

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <div className={"swiper-container"}>

            <div className={"background"}>
                <Image src={"/images/png/swiper_background.png"} layout={"fill"} objectFit={"fill"}
                       alt={"swiper_background"}/>
            </div>

            <div className={"swiper-box"}>
                <img className={'prev'} alt="arrow_left" src={"/images/png/slider_arrow.png"} onClick={goPrev}/>

                <div className={"two-swiper"}>
                    <Swiper
                        ref={swiperRef}
                        className="mySwiper partners-swiper"
                        spaceBetween={0}
                        loop={true}
                        slideToClickedSlide={true}
                        centeredSlides={true}
                        centeredSlidesBounds={true}
                        thumbs={{swiper: thumbsSwiper}}
                        modules={[FreeMode, Navigation, Thumbs]}
                        speed={500}
                        slidesPerView={"auto"}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            800: {
                                slidesPerView: partners.length < 3 ? 1 : 3,
                            },
                            1400: {
                                slidesPerView: partners.length < 5 ? !(partners.length % 2) ? partners.length - 1 : partners.length : 5,
                            },
                        }}
                    >
                        {partners?.map((item) => (
                            <SwiperSlide key={item.id}>
                                {({isActive}) => {
                                    isActive && setInfo(item.name, item[locale]?.description);
                                    return (
                                        <>
                                            {isActive ? (
                                                <a href={item.link} target="_blank" rel="noopener noreferrer">
                                                    <Image
                                                        className={"content__image"}
                                                        alt={'partner'}
                                                        src={item.img}
                                                        loader={myLoader}
                                                        width={157}
                                                        height={157}
                                                    />
                                                </a>
                                            ) : (
                                                <Image
                                                    className={"content__image"}
                                                    alt={'partner'}
                                                    src={item.img}
                                                    loader={myLoader}
                                                    width={157}
                                                    height={157}
                                                />
                                            )}
                                        </>
                                    );
                                }}
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <Swiper
                        onSwiper={setThumbsSwiper}
                        loop={true}
                        spaceBetween={0}
                        slidesPerView={partners.length < 4 ? partners.length : 4}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper2"
                        observer={false}
                    >
                        {partners?.map((item) =>
                            <SwiperSlide key={item.id}>
                                {
                                    ({isActive}) => {
                                        isActive && setInfo(item.name, item[locale]?.description)
                                        return <Image className={"content__image"} alt={'partner'}
                                                      src={item.img}
                                                      loader={myLoader}
                                                      width={73}
                                                      height={73}/>

                                    }
                                }
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>

                <img className={'next'} alt="arrow_left" src={"/images/png/slider_arrow.png"} onClick={goNext}/>

            </div>
            {/*<div className={"bot-line"}/>*/}
            {/*<div className={"company-description"} dangerouslySetInnerHTML={{__html: description}}/>*/}
        </div>

    );
}


export default PartnersCarousel;

