import React from 'react';
import {CustomChat, FacebookProvider} from "react-facebook";

function FacebookMsg() {
    return (
        <FacebookProvider appId={'779841443508918'} chatSupport>
            <CustomChat pageId={'1429432460651741'} minimized={true}/>
        </FacebookProvider>
    );
}

export default FacebookMsg;
