import React, {useEffect, useState} from "react";

export default function FileInputWithPreview({
                                                 placeholder = "",
                                                 name,
                                                 register,
                                                 setValue,
                                                 errorMessage,
                                                 value,
                                                 className = "",
                                                 watch,
                                             }) {
    const [changeLabel, setChangeLabel] = useState("");

    useEffect(() => {
        register(name);
        // eslint-disable-next-line
    }, [watch(name)]);

    const input = typeof window !== 'undefined' ? document.querySelector('#kk') : null
    const reader = typeof window !== 'undefined' && new FileReader();
    const fileByteArray = [];

    const handleChange = (e) => {
        console.log(e)
        try {
            setValue(name, e.target.files[0]);
            setChangeLabel(e.target.files[0]?.name || "");

            (typeof window !== 'undefined' && e.path) && input?.addEventListener('change', (e) => {
                typeof e.path[0].value !== "string" &&
                reader.readAsArrayBuffer(e.target.files[0]);
                reader.onloadend = (evt) => {
                    if (evt.target.readyState === FileReader.DONE) {
                        const arrayBuffer = evt.target.result,
                            array = new Uint8Array(arrayBuffer);
                        for (const a of array) {
                            fileByteArray.push(a);
                        }
                    }
                }
            })
        } catch (e) {
        }
    };

    return (
        <div className={"file-input-with-preview"}>
            <div className="">
                {value && <p>new image (proportions: 1x1)</p>}
                <input id={"kk"} type="file" onChange={(e) => handleChange(e)}/>
                {/*<label className="" htmlFor={name}>
                    {changeLabel || (placeholder || label)}

                </label>*/}
                {/*{errorMessage && <FormHelperText style={{color: "#f44336"}}>{errorMessage}</FormHelperText>}*/}
            </div>

        </div>
    );
}