import React, {useState} from "react";

const YoutubeEmbed = ({embedId, classname, homePage}) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className={classname}>
            <div className={"responsive-inner"}>
                {
                    !isLoading && homePage ?
                        <div className={"play-button"}>
                            <img className={"button"} src={"/images/svg/play-button.svg"} alt={"playButton"}
                                 onClick={() => setIsLoading(true)}/>
                            <p>Watch Demo</p>
                        </div>
                        :
                        <iframe
                            src={`https://www.youtube-nocookie.com/embed/${embedId}?autoplay=1&mute=1&rel=0`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                }
            </div>
        </div>
    )
};

export default YoutubeEmbed;
