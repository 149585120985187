function CircleItem2(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="81" height="79"
             viewBox="0 0 81 79">
            <defs>
                <style>{`.a{fill:#233e99;}.b{fill:#fff;}.c{filter:url(#a);}`}</style>
                <filter id="a" x="0" y="0" width="81" height="79" filterUnits="userSpaceOnUse">
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="3" result="b"/>
                    <feFlood floodOpacity="0.161"/>
                    <feComposite operator="in" in2="b"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g transform="translate(9 6)">
                <g className="c" transform="matrix(1, 0, 0, 1, -9, -6)">
                    <ellipse className="a" cx="31.5" cy="30.5" rx="31.5" ry="30.5" transform="translate(9 6)"/>
                </g>
                <g transform="translate(19.309 18.5)">
                    <path className="b"
                          d="M19.755,7.857a1.211,1.211,0,0,1-.826-.81h0A8.589,8.589,0,1,0,2.717,12.67,1.123,1.123,0,0,1,2.387,14,5.905,5.905,0,0,0,.061,19.582,6.036,6.036,0,0,0,6.1,24.628H11.81a1.074,1.074,0,0,0,1.074-1.074h0a1.074,1.074,0,0,0-1.074-1.074H6.1a3.88,3.88,0,0,1-3.915-3.2,3.711,3.711,0,0,1,1.477-3.557A3.243,3.243,0,0,0,4.724,11.9a6.563,6.563,0,0,1-.078-4.43A6.4,6.4,0,0,1,9.894,3.209a6.559,6.559,0,0,1,.829-.054,6.425,6.425,0,0,1,6.18,4.6A3.2,3.2,0,0,0,19.06,9.887,6.371,6.371,0,0,1,19.9,21.794a1.048,1.048,0,0,0-.57.953h0a1.061,1.061,0,0,0,1.49.982c4.438-2.133,6.85-7.745,3.092-13.167A7.446,7.446,0,0,0,19.755,7.857Z"
                          transform="translate(-0.001 -0.889)"/>
                    <path className="b"
                          d="M19.275,16.993a1.074,1.074,0,0,0,0-1.518l-1.7-1.7a3.221,3.221,0,0,0-4.554,0l-1.7,1.7a1.074,1.074,0,1,0,1.518,1.518L14.221,15.6v8.145a1.074,1.074,0,0,0,2.147,0V15.6l1.388,1.388a1.074,1.074,0,0,0,1.518,0Z"
                          transform="translate(0.809 -0.009)"/>
                </g>
            </g>
        </svg>
    );
}

export default CircleItem2;