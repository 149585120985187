function CircleItem1(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="82" height="82"
             viewBox="0 0 82 82">
            <defs>
                <style>{`.a{fill:#fff;}.b{fill:#233e99;}.c{filter:url(#a);}`}</style>
                <filter id="a" x="0" y="0" width="82" height="82" filterUnits="userSpaceOnUse">
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="3" result="b"/>
                    <feFlood floodOpacity="0.522"/>
                    <feComposite operator="in" in2="b"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g transform="translate(9 6)">
                <g className="c" transform="matrix(1, 0, 0, 1, -9, -6)">
                    <circle className="a" cx="32" cy="32" r="32" transform="translate(9 6)"/>
                </g>
                <g transform="translate(20.79 21.683)">
                    <path className="b"
                          d="M22.293,21.324H4.846a2.908,2.908,0,0,1-2.908-2.908V.969A.969.969,0,0,0,0,.969V18.416a4.852,4.852,0,0,0,4.846,4.846H22.293a.969.969,0,0,0,0-1.939Z"
                          transform="translate(0 0)"/>
                    <path className="b"
                          d="M5.969,19.723a.969.969,0,0,0,.969-.969V11.969a.969.969,0,0,0-1.939,0v6.785A.969.969,0,0,0,5.969,19.723Z"
                          transform="translate(-0.154 -0.338)"/>
                    <path className="b" d="M10,9.969v8.723a.969.969,0,0,0,1.939,0V9.969a.969.969,0,1,0-1.939,0Z"
                          transform="translate(-0.307 -0.277)"/>
                    <path className="b" d="M15,12.969v5.816a.969.969,0,1,0,1.939,0V12.969a.969.969,0,1,0-1.939,0Z"
                          transform="translate(-0.461 -0.369)"/>
                    <path className="b" d="M20,8.969v9.693a.969.969,0,0,0,1.939,0V8.969a.969.969,0,0,0-1.939,0Z"
                          transform="translate(-0.615 -0.246)"/>
                    <path className="b"
                          d="M5.969,8.723a.969.969,0,0,0,.685-.284L10.13,4.964a.994.994,0,0,1,1.371,0l2.105,2.105a2.908,2.908,0,0,0,4.112,0l5.414-5.414A.969.969,0,1,0,21.761.284L16.347,5.7a.969.969,0,0,1-1.371,0l-2.105-2.1a2.908,2.908,0,0,0-4.112,0L5.284,7.069a.969.969,0,0,0,.685,1.655Z"
                          transform="translate(-0.154 0)"/>
                </g>
            </g>
        </svg>
    );
}

export default CircleItem1;