import {Modal} from "react-bootstrap";
import Link from "next/link";
import {useRouter} from "next/router";
import UseTranslations from "../utils/useTranslations";
import close from '../public/images/svg/close.svg'
import Image from "next/image";

export default function ShowMoreModal(props) {
    const {t, locale} = UseTranslations();
    const rout = useRouter();

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName={`home-show-more-popup ${props.bgColor} ${props.className}`}
        >
            <Modal.Header closeButton={false}>
                <button className="modal-close-btn" onClick={props.onHide}>
                    <Image
                        src={close}
                        alt="close"
                    />
                </button>
            </Modal.Header>
            <Modal.Body className={"modal-body"}>
                <div className="modal-image">
                    <img src={props.loader ? '/api/uploads/' + props.img : props.img} alt={'project'}/>
                </div>
                <div dangerouslySetInnerHTML={{__html: props.info}}/>
                {
                (props.pdfGeo || props.pdfRus || props.pdfEng) &&
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: 5}}>
                        <p style={{margin: 0}}>{locale === "ka" ? 'სრულად ნახვა: ' : 'Learn more'}</p>
                        <img className={"button"} src={"/images/svg/pdf_icon.svg"} alt={"playButton"}/>
                        {props.pdfGeo && <>
                            <Link href={`/api/uploads/${props?.pdfGeo}`}>
                                <a target="_blank">GEO</a>
                            </Link>/</>}
                        {props.pdfEng && <>
                            <Link href={`/api/uploads/${props?.pdfEng}`}>
                                <a target="_blank">ENG</a>
                            </Link>/</>}
                        {props.pdfRus &&
                            <Link href={`/api/uploads/${props?.pdfRus}`}>
                                <a target="_blank">RUS</a>
                            </Link>}
                    </div>
                }
                {
                    props.redirectAddress &&
                    <div style={{position: "absolute", display: "flex", justifyContent: "end", right: 0, bottom: -30}}>
                        <Link href={props.redirectAddress}>
                            <p style={{
                                margin: 0,
                                cursor: "pointer",
                                color: '#233E99',
                                fontWeight: "bold",
                                fontSize: 19
                            }}>
                                {locale === "ka" ? 'პროექტების ნახვა' : "Show projects"}
                                <div className={'arrow-right'}/>
                            </p>
                        </Link>
                    </div>
                }
            </Modal.Body>
        </Modal>
    );
}
